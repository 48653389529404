<template>
    <v-row>
        <v-col>
            <v-card class="pa-4">
                <v-card-title><a @click="goToList">{{ site.title }}</a>&nbsp;-&nbsp;{{ entity.title }}</v-card-title>
                <v-card-text>
                    <EntityTabLinks />
                    <v-row class="pl-3 pb-3" />
                    <v-form>
                        <v-row>
                            <v-col>
                                <v-text-field
                                    v-model="entity.title"
                                    outlined
                                    label="Название"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field
                                    v-model="entity.phone"
                                    outlined
                                    label="Телефон"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field
                                    v-model="entity.address"
                                    outlined
                                    label="Адрес"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                Координаты<br>
                                <v-text-field
                                    v-model="entity.coordinates[1]"
                                    class="mt-1"
                                    outlined
                                    label="Широта"
                                    hide-details
                                />
                            </v-col>
                            <v-col>
                                <br>
                                <v-text-field
                                    v-model="entity.coordinates[0]"
                                    class="mt-1"
                                    outlined
                                    label="Долгота"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-textarea
                                    v-model="entity.work_time"
                                    outlined
                                    label="Часы работы"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-switch
                                    v-model="entity.enabled"
                                    label="Активен"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions class="pa-4 d-flex justify-space-between">
                    <div>
                        <v-btn
                            color="primary"
                            class="mr-3"
                            @click="save"
                        >Сохранить</v-btn>
                        <v-btn
                            color="light"
                            class="mr-3"
                            @click="goBack"
                        >Отмена</v-btn>
                    </div>
                    <v-btn
                        v-if="isAdmin"
                        color="error"
                        @click="deleteModal = true"
                    >Удалить</v-btn>
                </v-card-actions>
            </v-card>
        </v-col>


        <v-dialog
            v-model="deleteModal"
            width="800px"
        >
            <v-card>
                <v-card-title class="error">
                    Подтверждение
                </v-card-title>

                <v-card-text>
                    Вы действительно хотите удалить сервис? Это действие нельзя будет отменить.
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-btn
                        color="error"
                        @click="remove"
                    >Удалить</v-btn>
                    <v-spacer />
                    <v-btn
                        color="light"
                        @click="deleteModal = false"
                    >Отмена</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
    import axios from 'axios';
    import store from '../../store';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { mapState } from 'vuex';
    import { yandexMap, ymapMarker } from 'vue-yandex-maps';
    import EntityTabLinks from '../../components/sites/EntityTabLinks';

    import { imageSrc, translit } from '../../helpers';

    export default {
        name: 'EntityEdit',

        components: {
            EntityTabLinks,
            yandexMap, 
            ymapMarker
        },

        data() {
            return {
                imageSrc,
                entity: {
                    site_id: null,
                    title: '',
                    enabled: true,
                    position: 0,
                    coordinates: []
                },
                mapZoom: 12,
                deleteModal: false,
            };
        },

        computed: {
            ...mapState('auth', ['user']),
            ...mapState('sites', {
                site: state => state.entity
            }),
            ...mapState('entities', {
                oldEntity: state => state.entity
            }),
            ...mapState('siteTypes', {
                siteTypes: state => state.entities
            }),
            siteLinkPreset() {
                return `${ process.env.VUE_APP_FRONT_URL }/entity/`
            },
            isAdmin() {
                return this.user.admin;
            },
        },

        async mounted() {
            const { site_id, id } = this.$route.params;
            await store.dispatch('siteTypes/fetch');
            if(site_id) {
                await store.dispatch('sites/get', { id: site_id });
                this.entity.site_id = site_id;
            }
            if(site_id && id) {
                await store.dispatch('entities/get', { site_id, id });
                this.entity = {
                    ...this.entity,
                    ...this.oldEntity
                };
                this.entity.coordinates = this.entity.coordinates || [];
            }
        },

        methods: {
            async save() {
                try {
                    store.commit('entities/SET_ENTITY', this.entity);
                    await store.dispatch('entities/save', { site_id: this.$route.params.site_id });
                    this.goBack();
                } catch (error) {
                    alert('Ошибка сохранения');
                }
            },
            async remove() {
                const { id } = this.$route.params;
                try {
                    await store.dispatch('entities/delete', { site_id: this.$route.params.site_id, id });
                    this.$router.push('/entities');
                } catch (error) {
                    alert('Ошибка удаления');
                }
            },
            goToList() {
                const { site_id } = this.$route.params;
                if(site_id) {
                    this.$router.push(`/sites/${site_id}/entities`);
                } else {
                    this.$router.push('/sites');
                }
            },
            goBack() {
                const { site_id, id } = this.$route.params;
                if(site_id && id) {
                    this.$router.push(`/sites/${site_id}/entities/${id}/detail`);
                } else if(site_id) {
                    this.$router.push(`/sites/${site_id}/entities`);
                } else {
                    this.$router.push('/sites');
                }
            },
        },
    };
</script>

<style lang="scss">
    .entity_edit {
        &__map {
            width: 100%;
            height: 400px;

            &__wrapper {
                width: 100%;
                height: 400px;
                border-radius: 4px;
                overflow: hidden;
            }
        }
    }
</style>